import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import videoPresentationEN from '../../images/home/CultureVideo.mp4';
import videoPresentationES from '../../images/home/CultureVideoES.mp4';
import video from '../../images/home/videoThumbnail.mp4';
import './presentationVideo.scss';
import { withPrefix } from 'gatsby';

const VideoPresentation = () => {
  const ccEngRef = useRef(null);
  const ccEsRef = useRef(null);
  const [show, setShow] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const { t, i18n } = useTranslation('index', {
    keyPrefix: 'VideoPresentation',
  });
  const videoRef = React.useRef(null);
  const currentLanguage = i18n.language;

  const videoPresentation = {
    en: videoPresentationEN,
    es: videoPresentationES,
  };
  const modalStyle = {
    backgroundColor: 'black',
    padding: '0',
  };

  const togglePause = () => {
    if (isPaused) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
    setIsPaused(!isPaused);
  };

  const ccEng = withPrefix('cc/cc_eng.vtt');
  const ccEs = withPrefix('cc/cc_es.vtt');
  return (
    <section className="videoPresentation">
      <Row>
        <Col className="videoSection">
          <div
            className="vid_1_wrapper"
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
          >
            <video muted autoPlay loop playsInline className="vid_1" ref={videoRef}>
              <source src={video} type="video/mp4" />
            </video>
            {isHovering && (
              <div className="pause-button-container">
                <button
                  className={`pause-button ${isPaused ? 'paused' : ''}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    togglePause();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    {isPaused ? (
                      <polygon points="9 17 15 12 9 7 9 17" />
                    ) : (
                      <>
                        <rect x="6" y="4" width="4" height="16" />
                        <rect x="14" y="4" width="4" height="16" />
                      </>
                    )}
                  </svg>
                </button>
              </div>
            )}
          </div>
        </Col>
        <Col className="buttonSection">
          <Col className="img" md={12} lg={12}></Col>
          <Col className="text" xs={10} sm={10} md={12} lg={12}>
            <h1>
              {t('Watch our 2-minute')} <br aria-hidden="true" />
              {t('company presentation')}{' '}
            </h1>
            <Button className="playVideoButton" variant="success" onClick={() => setShow(true)}>
              <i className="bi bi-play-fill" />
              {t("Let's Start")}
            </Button>
          </Col>
        </Col>
      </Row>
      <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={modalStyle}>
          <video
            width="100%"
            height="100%"
            className="videoYoutube"
            controls
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          >
            <source src={videoPresentation[currentLanguage]} type="video/mp4" />
            <track src={ccEng} kind="subtitles" srclang="en" label="English" ref={ccEngRef}></track>
            <track src={ccEs} kind="subtitles" srclang="es" label="Español" ref={ccEsRef}></track>
            {t('Your browser does not support the video tag.')}
          </video>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default VideoPresentation;
