import { withPrefix } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import paper from '../../files/Leadership-in-the-21st-century-an-oxymoron-May-2018.pdf';
import './events.scss';

const Events = ({ className }) => {
  const { t, i18n } = useTranslation('index', { keyPrefix: 'Events' });

  const getRosalbaPdfByLanguage = () => {
    return i18n.language === 'es'
      ? withPrefix('Rosalba_Reynoso_interview_es.pdf')
      : withPrefix('Rosalba_Reynoso_interview_en.pdf');
  };
  return (
    <section className={(className ? className + ' ' : '') + 'events'}>
      <Container fluid="xl">
        <Row>
          <Col className="" xl={12} lg={12} md={12} sm={12} xs={12}>
            <div className="max-events">
              <h1 className="header-square blue md-large lg-large event-header">{t('Events')}</h1>
              <Col className="event-collection" md={12} sm={12} xs={12}>
                <Col className="event-item " md={5} sm={12} xs={12}>
                  <Col className="event-img" md={12} sm={12} xs={12}>
                    <div className="eventLatina"></div>
                  </Col>
                  <Col className="event-detail" md={12} sm={12} xs={12}>
                    <Col className="event-title" md={12} sm={12} xs={12}>
                      {t('Latina STEM leaders spotlight')}
                    </Col>
                    <Col className="event-text" md={12} sm={12} xs={12}>
                      {t(
                        'In the first installment of our new series profiling Latinas taking the lead in the STEM sector, LABS talks with Rosalba Reynoso, the co-founder and CEO of Blue Trail Software.',
                      )}
                    </Col>
                    <Col className="event-button" xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Button
                        href={getRosalbaPdfByLanguage()}
                        target="_blank"
                        title={t('Read More Rosalba Paper')}
                        variant="success"
                        rel="noreferrer"
                      >
                        {t('Download')} <span>&#10140;</span>
                      </Button>
                    </Col>
                  </Col>
                </Col>
                <Col className="event-item" md={5} sm={12} xs={12}>
                  <Col className="event-img" md={12} sm={12} xs={12}>
                    <div className="eventLeadership"></div>
                  </Col>
                  <Col className="event-detail" md={12} sm={12} xs={12}>
                    <Col className="event-title" md={12} sm={12} xs={12}>
                      {t('Leadership in The Digital Age')}
                    </Col>
                    <Col className="event-text" md={12} sm={12} xs={12}>
                      {t(
                        'The digital age enables novel organizational models, where power is distributed across the organization, in other words where each employee is entrusted with a certain level of decision power.',
                      )}
                    </Col>
                    <Col className="event-button" xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Button
                        href={paper}
                        target="_blank"
                        title={t("Download Remi's Paper")}
                        variant="success"
                      >
                        {t('Download')} <span>&#10140;</span>
                      </Button>
                    </Col>
                  </Col>
                </Col>
              </Col>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Events;
