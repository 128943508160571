import * as React from 'react';

import './mainpage.scss';
import Layout from '../components/layout/layout';
import Seo from '../components/seo';
import FindDreamJob from '../components/careers/finddreamjob';
import SayAbout from '../components/home/sayabout';
import ShowCase from '../components/home/ourshowcase';
import Events from '../components/home/events';
import HeaderBlue from '../components/home/headerblue';
import Projects from '../components/home/projects';
import VideoPresentation from '../components/home/presentationVideo';
import { graphql } from 'gatsby';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';

const IndexPage = ({ location }) => {
  const { i18n } = useI18next();
  const { t } = useTranslation('common', { keyPrefix: 'seo' });
  return (
    <>
      <Layout headerTransparent={true} lang={i18n.resolvedLanguage}>
        <Seo title={t('Home')} lang={i18n.resolvedLanguage} description={t('description')} />

        <div className='max-container-pages'>
          <HeaderBlue />
          <VideoPresentation />
          <ShowCase />
          <Projects previous={location.pathname} />
          <SayAbout />
          <Events />
          <FindDreamJob />
        </div>
      </Layout>
    </>
  );
};

export default IndexPage;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
